import createPromise from "nq/src/createPromise";
import BaseFormPresenter from "../../base/BaseFormPresenter";
import { deleteImageUseCase, saveImageUseCase } from "../../usecases/file";
import ConfirmDialog from "../../base/ProgressDialog";
import { dialog } from "nq-component";
class ProductFormPresenter extends BaseFormPresenter {
  constructor(view, getObjectUseCase, upsertUseCase, findObjectUseCase) {
    super(view, getObjectUseCase, upsertUseCase);
    this.findObjectUseCase = findObjectUseCase;
  }
  componentDidMount() {
    this.init();
    this.getObject();
  }

  showSuccess(message, title) {
    const options = {
      title: title || "Success",
      message: message,
      icon: "bi bi-check-circle",
      type: "success",
      positiveButton: "OKAY",
      negativeButton: false,
    };
    return this.showDialog(options);
  }

  showDialog({ title, message, icon, type, ...options }) {
    const promise = createPromise();
    dialog.fire({
      html: (
        <ConfirmDialog
          icon={icon}
          title={title}
          message={message}
          type={type}
        />
      ),
      onPositiveClick: () => {
        promise.resolve();
      },
      onNegativeClick: () => {
        promise.reject();
      },
      ...options,
    });
    return promise;
  }

  async save() {
    const user = this.view.getCurrentUser();

    const collection = this.view.getCollectionName();
    if (this.object.id) {
      this.change.id = this.object.id;
      this.change.acl = this.view.getAcl();
    } else {
      this.change.acl = this.view.getAcl();
    }
    try {
      this.change.createdBy = { id: user.id };
      console.log(this.change, "changes");

      this.change.status = "Pending";

      if (this.change.isShortTerm === undefined) {
        this.change.isShortTerm = "No";
      }
      if (this.change.isForeclosure === undefined) {
        this.change.isForeclosure = "No";
      }
      if (this.change.isPreselling === undefined) {
        this.change.isPreselling = "No";
      }
      if (this.change.showPrice === undefined) {
        this.change.showPrice = true;
      }

      await this.upsertUseCase.execute(collection, this.change);
      await this.view.showSuccess(
        `Property Added Successfully! Wait the admin to review your property`
      );
      this.view.navigateBack();
    } catch (error) {
      throw error;
    }
  }
}

export default ProductFormPresenter;
