import React from 'react'
import { Checkbox } from 'nq-component'

function Head({
  selectable,
  fields,
  excludeFields,
  actionsList,
  selected,
  onSelectAll,
  objects,
}) {
  return (
    <tr>
      {selectable && (
        <th>
          <Checkbox
            className='align-middle'
            id='check_all'
            checked={objects.length === selected.length && objects.length !== 0}
            onChange={(checked) => onSelectAll(checked)}
          />
        </th>
      )}
      {Object.keys(fields).map((field, index) => {
        const { type, ...options } = fields[field]
        if (excludeFields.includes(field)) return null
        const label = options.label || field
        return (
          <th
            key={field}
            className='fs-xs align-middle text-nowrap text-capitalize '
          >
            {label}
          </th>
        )
      })}
      {actionsList.length > 0 && (
        <>
          <th>
            <div style={{ width: '50px' }}></div>
          </th>
          <th
            className='fs-xs align-middle text-nowrap'
            colSpan={actionsList.length}
          >
            ACTIONS
          </th>
        </>
      )}
    </tr>
  )
}

export default Head
