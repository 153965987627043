import React from "react";
import OutputFactory from "nq-component/dist/OutputFactory";

function CollapseView(
  index,
  object,
  excludeFields,
  fields,
  actions,
  onClickItem,
  fieldImage
) {
  return (
    <>
      <div className="d-flex">
        {fieldImage && (
          <div
            className="mb-3"
            style={{
              width: "100px",
              height: "100px",
            }}
          >
            <img
              className="w-100 h-100"
              style={{ objectFit: "cover" }}
              src={object[fieldImage]}
            />
          </div>
        )}
        <ul className="list-unstyled ms-1">
          {Object.keys(fields).map((field) => {
            const options = fields[field];

            if (options._type === "Image") return null;
            if (options._type === "Feature") return null;
            if (options.detail !== true && excludeFields.includes(field))
              return null;
            const output = (
              <OutputFactory field={field} object={object} {...options} />
            );

            if (!output) return null;
            return (
              <li className="d-flex align-items-center gap-1" key={field}>
                <span className="ms-2 fw-light text-capitalize fw-semibold">
                  {options.label || field}:
                </span>
                <span className="fs-sm text-nowrap">{output}</span>
              </li>
            );
          })}
        </ul>
      </div>
      {actions.length > 0 ? (
        actions.map((action) => {
          if (action.label === "ACCEPT" || action.label === "REJECT") {
            if (object?.status === "Accepted" || object?.status === "Rejected")
              return null;
            return (
              <button
                className={
                  action.className
                    ? action.className
                    : "btn btn-primary btn-sm ms-2"
                }
                onClick={() => action.onClick(index)}
              >
                {action.label}
              </button>
            );
          }
          if (action.label === "UNHIDE") {
            if (
              object?.status === "Posted" ||
              object?.status === "Pending" ||
              object?.status === "Rejected"
            )
              return null;
            return (
              <button
                className={
                  action.className
                    ? action.className
                    : "btn btn-primary btn-sm ms-2"
                }
                onClick={() => action.onClick(index)}
              >
                {action.label}
              </button>
            );
          }
          if (action.label === "HIDE" || action.label === "BOOST") {
            if (
              object?.status === "Hidden" ||
              object?.status === "Pending" ||
              object?.status === "Rejected"
            )
              return null;
            return (
              <button
                className={
                  action.className
                    ? action.className
                    : "btn btn-primary btn-sm ms-2"
                }
                onClick={() => action.onClick(index)}
              >
                {action.label}
              </button>
            );
          }

          return (
            <button
              className={
                action.className
                  ? action.className
                  : "btn btn-primary btn-sm ms-2"
              }
              onClick={() => action.onClick(index)}
            >
              {/* {object.status} */}
              {action.label}
            </button>
          );
        })
      ) : (
        <button
          className="btn btn-primary btn-sm"
          onClick={() => onClickItem(index)}
        >
          VIEW
        </button>
      )}
    </>
  );
}

export default CollapseView;
