import React from "react";
import BaseFormPage from "../../base/BaseFormPage";
import withRouter from "../../withRouter";
import ProductFormPresenter from "./ProductFormPresenter";
import {
  findObjectUseCase,
  getObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import { Bed, Size, Toilet } from "../../myIcons";
import { InputImage, Progress, dialog } from "nq-component";
import NavBar from "../../components/navbar";
import productSubtype from "./productSubtype.json";
import productIndoor from "./productIndoor.json";
import productOutdoor from "./productOutdoor.json";
import FormFactory from "../../components/ApplicationForm";
class ProductFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.presenter = new ProductFormPresenter(
      this,
      getObjectUseCase(),
      upsertUseCase(),
      findObjectUseCase()
    );
    this.state = {
      object: {},
      submitting: false,
      subtype: [],
    };
  }

  componentDidMount() {
    this.presenter.componentDidMount();
  }

  setSubtype(subtype) {
    this.setState({ subtype });
  }

  onChangeImages(image) {
    this.presenter.onChange(image);
  }

  getImages() {
    return this.state.object.photos;
  }

  getCollectionName() {
    return "product";
  }
  getAcl() {
    const roles = this.getCurrentRoles();
    const aclRoles = roles.map((r) => `role:${r.name}`);
    aclRoles.push("role:admin");
    // console.log(aclRoles)
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, ...aclRoles],
      write: [user.id, ...aclRoles],
    };
  }

  onChange(fieldVal, value) {
    this.presenter.onChange(fieldVal, value);
  }

  getProductSchema() {
    const product = this.getSchema("product");
    const schema = {
      ...product,
      fields: {
        ...product.fields,

        subcategory: {
          ...product.fields.subcategory,
          options:
            this.state.object.category === "Condominium"
              ? productSubtype.Condominium
              : this.state.object.category === "Commercial"
              ? productSubtype.Commercial
              : this.state.object.category === "Apartment"
              ? productSubtype.Apartment
              : this.state.object.category === "House"
              ? productSubtype.House
              : this.state.object.category === "Land"
              ? productSubtype.Land
              : [],
        },
        indoor: {
          ...product.fields.indoor,
          options:
            this.state.object.category === "Condominium"
              ? productIndoor.Condominium
              : this.state.object.category === "Commercial"
              ? productIndoor.Commercial
              : this.state.object.category === "Apartment"
              ? productIndoor.Apartment
              : this.state.object.category === "House"
              ? productIndoor.House
              : [],
        },
        outdoor: {
          ...product.fields.outdoor,
          options:
            this.state.object.category === "Condominium"
              ? productOutdoor.Condominium
              : this.state.object.category === "Commercial"
              ? productOutdoor.Commercial
              : this.state.object.category === "Apartment"
              ? productOutdoor.Apartment
              : this.state.object.category === "House"
              ? productOutdoor.House
              : this.state.object.category === "Land"
              ? productOutdoor.Land
              : [],
        },
      },
    };

    return schema;
  }

  closeDialog() {
    dialog.close();
  }

  navigateBack() {
    this.navigateTo("/product/list");
  }
  render() {
    const { object, submitting } = this.state;
    console.log(object);

    const schema = this.getProductSchema();
    return (
      <>
        <div className="sticky-top">
          <NavBar />
        </div>
        <div className="container-fluid p-4 relative overflow-auto">
          {submitting && (
            <div className="position-absolute top-50 start-50 translate-middle z-3">
              <Progress size="lg" />
            </div>
          )}
          <h1 className="fw-semibold">Manage Properties</h1>
          <p className="fs-sm">
            Let's start with basic "typology" of the listing so that property
            seekers can find it under the right category on EBOWR
          </p>
          <form className="row col-12 " onSubmit={this.onSubmitForm.bind(this)}>
            <div className="">
              <FormFactory
                // hasLabel={false}
                excludeFields={["createdBy", "status", "featuredOrder"]}
                object={object}
                schema={schema}
                onChange={this.onChange.bind(this)}
              />

              <div className="col-md-6 my-4 d-flex justify-content-end">
                <button
                  type="submit"
                  className="btn btn-primary w-100 "
                  disabled={submitting}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
    );
  }
}
export default withRouter(ProductFormPage);
