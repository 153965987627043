import React from "react";
import Queue from "nq";
import { dialog } from "nq-component";
import classNames from "../../classNames";
import CameraCapture from "../CameraCapture";
import click from "../../click";

const defaultProps = {
  height: "250px",
};

function InputCamera({
  className,
  value,
  onChange,
  saveImage,
  print,
  height,
  disabled,
  object,
}) {
  const [progress, setProgress] = React.useState(0);
  const [image, setImage] = React.useState(null);
  const printRef = React.useRef();

  function readImage(file) {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImage(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  function onClick() {
    const capture = async (canvas) => {
      dialog.close();
      setImage(canvas.toDataURL());
      const file = await Queue.canvasToBlob(canvas, "capture.png", "image/png");
      await saveImage.execute(file);
    };
    dialog.fire({
      component: (
        <div>
          <button
            onClick={() => dialog.close()}
            className="btn btn-sm btn-link p-0 float-end"
          >
            <i className="bi bi-x"></i>
          </button>
          <CameraCapture onCapture={capture} />
        </div>
      ),
      footer: false,
    });
  }

  function onClickIcon(e) {
    e.stopPropagation();
    onClick();
  }

  // new
  function onFileSelected(file) {
    readImage(file);
    const options = {
      progress: (value) => setProgress(value * 100),
    };
    saveImage
      .execute(file, options)
      .then((result) => {
        setImage(result.url);
        setProgress(0);
        onChange(result.url);
      })
      .catch((error) => {
        console.log("error", error);
      });
  }

  function onClickBrowse() {
    if (disabled) return;
    const input = document.createElement("input");
    input.type = "file";
    input.accept = "img";
    input.onchange = (e) => {
      const [file] = e.target.files;
      file && onFileSelected(file);
    };
    click(input);
  }

  return (
    <>
      <div
        onClick={onClickBrowse}
        className={classNames(
          "rounded bg-light d-flex position-relative cursor-pointer",
          className
        )}
        style={{ height: "250px" }}
      >
        <div className="position-absolute h-100 w-100 d-flex justify-content-center">
          <img className="img-fluid" src={image || object} />
        </div>
        {!(image || object) && (
          <div className="position-absolute h-100 w-100 d-flex">
            <i className="bi bi-camera-fill display-3 text-dark m-auto" />
          </div>
        )}
        {progress > 0 && progress !== 100 && (
          <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
        )}
        {progress > 0 && progress !== 100 && (
          <div className="position-absolute h-100 w-100 d-flex">
            <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
          </div>
        )}
        <div className="position-absolute w-100 text-end p-2">
          <button
            type="button"
            onClick={onClickIcon}
            className="btn btn-secondary"
          >
            <i className="bi bi-camera-fill"></i>
          </button>
        </div>
      </div>
    </>
  );
}

InputCamera.defaultProps = defaultProps;
export default InputCamera;
// import React from "react";
// import Queue from "nq";
// import { dialog } from "nq-component";
// import classNames from "../../classNames";
// import CameraCapture from "../CameraCapture";

// const defaultProps = {
//   height: "250px",
// };

// function InputCamera({ className, value, onChange, saveImage, print, height }) {
//   const [progress, setProgress] = React.useState(0);
//   const [image, setImage] = React.useState(null);
//   const printRef = React.useRef();

//   function readImage(file) {
//     const reader = new FileReader();
//     reader.onload = (e) => {
//       setImage(e.target.result);
//     };
//     reader.readAsDataURL(file);
//   }

//   async function capture(canvas) {
//     try {
//       dialog.close();
//       setImage(canvas.toDataURL());
//       const file = await Queue.canvasToBlob(canvas, "capture.png", "image/png");
//       if (saveImage && typeof saveImage === "function") {
//         await saveImage(file); // Directly calling saveImage without execute
//         setProgress(100);
//       } else {
//         console.error("saveImage is not a function or is not defined");
//       }
//     } catch (error) {
//       console.error("Error saving image:", error);
//     }
//   }

//   function onClick() {
//     dialog.fire({
//       component: (
//         <div>
//           <button
//             onClick={() => dialog.close()}
//             className="btn btn-sm btn-link p-0 float-end"
//           >
//             <i className="bi bi-x"></i>
//           </button>
//           <CameraCapture onCapture={capture} />
//         </div>
//       ),
//       footer: false,
//     });
//   }

//   function onClickIcon(e) {
//     e.stopPropagation();
//     onClick();
//   }

//   return (
//     <>
//       <div
//         onClick={onClick}
//         className={classNames(
//           "rounded bg-light d-flex position-relative cursor-pointer",
//           className
//         )}
//         style={{ height: height || defaultProps.height }}
//       >
//         <div className="position-absolute h-100 w-100 d-flex justify-content-center">
//           <img className="img-fluid" src={image || value} />
//         </div>
//         {!(image || value) && (
//           <div className="position-absolute h-100 w-100 d-flex">
//             <i className="bi bi-camera-fill display-3 text-dark m-auto" />
//           </div>
//         )}
//         {progress > 0 && progress !== 100 && (
//           <div className="position-absolute h-100 w-100 bg-black bg-opacity-50" />
//         )}
//         {progress > 0 && progress !== 100 && (
//           <div className="position-absolute h-100 w-100 d-flex">
//             <h3 className="m-auto text-white">{progress.toFixed(0)}%</h3>
//           </div>
//         )}
//         <div className="position-absolute w-100 text-end p-2">
//           <button
//             type="button"
//             onClick={onClickIcon}
//             className="btn btn-secondary"
//           >
//             <i className="bi bi-camera-fill"></i>
//           </button>
//         </div>
//       </div>
//     </>
//   );
// }

// InputCamera.defaultProps = defaultProps;
// export default InputCamera;
