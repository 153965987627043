import React from "react";
// import Checkbox from "../Checkbox";
// import Accordion from "../Accordion";
// import OutputFactory from "../OutputFactory";
import CollapseView from "./CollapseView";
// import AccordionProvider from "../Accordion/AccordionProvider";
// import AccordionCollapse from "../Accordion/AccordionCollapse";

import { Checkbox, Accordion, OutputFactory } from "nq-component";
import AccordionProvider from "nq-component/dist/Accordion/AccordionProvider";
import AccordionCollapse from "nq-component/dist/Accordion/AccordionCollapse";

let timeoutId;

function Body({
  objects,
  fields,
  excludeFields,
  selected,
  selectable,
  onSelect,
  onClick,
  onLongPress,
  actionsList,
  collapsable,
  onCollapse,
  actions,
  onClickItem,
  fieldImage,
}) {
  const onPointerDown = () => {
    timeoutId = setTimeout(onLongPress, 500); // set long press time to 500ms
  };

  const onPointerUp = () => {
    clearTimeout(timeoutId);
  };
  return objects.map((object, index) => {
    const id = object.id || object._id;
    const checked = selected.includes(object);
    return (
      <AccordionProvider key={id}>
        <tr>
          {selectable && (
            <th className="align-middle">
              <Checkbox
                checked={checked}
                id={"check_" + id}
                onChange={() => onSelect(index)}
              />
            </th>
          )}

          {Object.keys(fields).map((field, i) => {
            const options = fields[field];
            if (excludeFields.includes(field)) return null;
            return (
              <Accordion
                as="td"
                eventKey={`_${id}`}
                key={field}
                className="fs-sm text-truncate"
                onPointerDown={onPointerDown}
                onPointerUp={onPointerUp}
                // onClick={() => onClick(index, field)}
                style={{
                  cursor: "pointer",
                  maxWidth: "15em",
                }}
              >
                {<OutputFactory field={field} object={object} {...options} />}
              </Accordion>
            );
          })}
          {actionsList.length > 0 && (
            <td>
              <div style={{ width: "50px" }}></div>
            </td>
          )}
          {actionsList.map((action) => {
            return (
              <td className="text-truncate">
                <button
                  onClick={action.onClick && action.onClick.bind(this, index)}
                  className="btn btn-outline-primary btn-sm fs-xs shadow-none"
                >
                  <i className={action.icon} /> {action.label}
                </button>
              </td>
            );
          })}
        </tr>
        {collapsable && (
          <tr>
            <td className="p-0" colSpan={Object.keys(fields).length + 1}>
              <AccordionCollapse
                toggleable
                className="collapse p-3 "
                eventKey={`_${id}`}
              >
                {onCollapse(index, object, excludeFields) ||
                  CollapseView(
                    index,
                    object,
                    excludeFields,
                    fields,
                    actions,
                    onClickItem,
                    fieldImage
                  )}
              </AccordionCollapse>
            </td>
          </tr>
        )}
      </AccordionProvider>
    );
  });
}

export default Body;
