import BaseFormPage from "../../base/BaseFormPage";
import SellerFormPresenter from "./SellerFormPresenter";
import { getObjectUseCase, upsertUseCase } from "../../usecases/object";
import NavBar from "../../components/navbar";
import React from "react";
import FormFactory from "../../components/FormFactory";
import withRouter from "../../withRouter";

class SellerFormPage extends BaseFormPage {
  constructor(props) {
    super(props);
    this.presenter = new SellerFormPresenter(
      this,
      new getObjectUseCase(),
      new upsertUseCase()
    );
  }

  getCollectionName() {
    return "sellerDocuments";
  }

  getObjectId() {
    const document = this.getGlobalState("sellerDocument");
    return document && document.id;
  }

  showSuccessSnackbar(message) {
    this.showSuccess(message).then(() => {
      this.navigateTo("/seller/approval");
      this.reload();
    });
  }

  onChange(value, field) {
    this.presenter.onChange(value, field);
  }

  render() {
    const object = this.state.object;
    const schema = this.getSchema(this.getCollectionName());
    if (!schema) return <h1>no schema</h1>;
    return (
      <>
        <NavBar />
        <div className="overflow-auto">
          <div className="h-100">
            <div className="p-3 p-lg-4">
              <h1 className="fw-bold mt-3 text-capitalize">Seller Document</h1>
              <div className="mt-3 bg-white shadow rounded p-3 px-lg-5 py-lg-4">
                <form onSubmit={this.onSubmitForm.bind(this)}>
                  <div className="row g-3">
                    <FormFactory
                      schema={schema}
                      object={object}
                      onChange={this.onChange.bind(this)}
                    />
                  </div>
                  <div className="mt-4">
                    <button
                      type="submit"
                      className="btn btn-primary fs-sm me-3"
                    >
                      <i className="bi bi-file-earmark-check me-2"></i>SAVE
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(SellerFormPage);
