import React from "react";
import classNames from "./classNames";

const noop = () => {};
const defaultProps = {
  loadOptions: noop,
  onChange: noop,
  onSearch: noop,
  onClickAdd: noop,
  onClickClear: noop,
  onFocus: noop,
  value: [],
  placeholder: "Select",
  dynamic: false,
};

function SelectSearch({
  className,
  value,
  placeholder,
  onChange,
  onSearch,
  onClickAdd,
  onClickClear,
  onFocus,
  focus,
  options,
  required,
  dynamic,
}) {
  const [selected, setSelected] = React.useState([]);

  React.useEffect(() => {
    if (Array.isArray(value)) {
      setSelected(value);
    }
  }, []);

  function onSelect(value, index) {
    if (selected.includes(value.value)) {
      // If the value is already selected, remove it
      setSelected(selected.filter((item) => item !== value.value));
    } else {
      // If the value is not selected, add it
      setSelected([...selected, value.value]);
    }

    // onChange(
    //   {
    //     value: ["CCTV", "asdsd"],
    //   },
    //   "indoor"
    // );
    // setText(value);
    // setOpen(false);
  }
  React.useEffect(() => {
    onChange(selected);
  }, [selected]);

  return (
    <div style={{ position: "relative" }}>
      <div className="d-flex flex-wrap gap-2">
        {options.map((option, index) => (
          <button
            key={option.value}
            type="button"
            className={`btn text-nowrap shadow-sm ${
              Array.isArray(value) && value.includes(option.value)
                ? "btn-primary"
                : "btn-secondary"
            }`}
            onClick={() => onSelect(option, index)}
          >
            {option.label}
          </button>
        ))}
      </div>
    </div>
  );
}

SelectSearch.defaultProps = defaultProps;
export default SelectSearch;
