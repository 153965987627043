import React from 'react';
import roundedRect from "./roundedRect";


class CameraCapture extends React.Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
        this.canvasRef = React.createRef();
        this.animationFrameId = null; // Store the animation frame ID
        this.state = {flip: true};
        this.progress = 0;
    }


    getCollectionName() {
        return "residents";
    }

    componentDidMount() {
        this.setupWebcam();
    }

    componentWillUnmount() {
        if (this.animationFrameId !== null) {
            cancelAnimationFrame(this.animationFrameId); // Cancel the animation frame
        }
        this.stopTracks();
    }

    stopTracks() {
        // Stop the current video stream before switching
        if (this.videoRef.current && this.videoRef.current.srcObject) {
            const tracks = this.videoRef.current.srcObject.getTracks();
            tracks.forEach(track => track.stop());
        }
    }

    async setupWebcam() {
        const options = {video: true};
        const stream = await navigator.mediaDevices.getUserMedia(options);
        const video = this.videoRef.current;
        const canvas = this.canvasRef.current;
        if (!video) return;
        video.srcObject = stream;
        video.onloadedmetadata = () => {
            video.play();
            canvas.width = video.videoWidth; // Set canvas width
            canvas.height = video.videoHeight; // Set canvas height
            this.processFrames();
        };
    }

    processFrames() {
        const video = this.videoRef.current;
        let startTime = performance.now(); // Here performance is the global object
        const drawFrame = async (timestamp) => {
            if (video.paused || video.ended) return;
            const elapsed = timestamp - startTime;
            const deltaTime = elapsed / 1000; // Convert milliseconds to seconds
            startTime = timestamp;
            await this.draw(deltaTime);
            this.animationFrameId = requestAnimationFrame(drawFrame); // Store the animation frame ID
        };
        this.animationFrameId = requestAnimationFrame(drawFrame); // Store the animation frame ID
    }


    async draw(delta) {
        const canvas = this.canvasRef.current;
        if (canvas === null) return;
        const video = this.videoRef.current;
        const context = canvas.getContext("2d");
        // Clear the canvas
        context.clearRect(0, 0, canvas.width, canvas.height);
        // Define the bounding box for the face region
        const centerX = canvas.width / 2;
        const centerY = canvas.height / 2;
        const radius = 150; // Adjust as needed
        // To Calculate the square bounding box dimensions
        const squareSize = radius * 2; // This will make the square have the same width and height as the diameter of the circle
        const squareX = centerX - radius;
        const squareY = centerY - radius;

        // Flip the canvas horizontally for video content
        if (this.state.flip) {
            context.save(); // Save the current state before transformation
            context.scale(-1, 1); // Flip horizontally
            context.translate(-canvas.width, 0); // Translate back to original position
        }
        // draw the video feed to the canvas
        context.drawImage(video, 0, 0, canvas.width, canvas.height);
        if (this.state.flip) {
            context.restore(); // Restore to the state before transformation
        }

        // Get the image data from the canvas
        const faceRegion = context.getImageData(squareX, squareY, squareSize, squareSize);
        // Create a separate canvas for the face region
        const faceCanvas = document.createElement("canvas");
        faceCanvas.width = squareSize;
        faceCanvas.height = squareSize;
        const faceCtx = faceCanvas.getContext("2d");
        // Put the face image data onto the face canvas
        faceCtx.putImageData(faceRegion, 0, 0);

        // draw the background clip
        context.beginPath();
        const x = squareX;
        const y = squareY;
        const width = squareSize;
        const height = squareSize;
        roundedRect(context, x, y, width, height, radius);
        //backdrop
        context.fillStyle = "#FFFFFF";
        context.rect(canvas.width, 0, -canvas.width, canvas.height);
        context.fill();

        // Draw FPS
        if (this.props.hasFPS) {
            const FPS = 1 / delta;
            context.font = "10px Arial";
            context.fillStyle = "green";
            context.fillText(`FPS: ${Math.ceil(FPS)}`, 20, 20);
        }

        if (this.videoState === 'CAPTURE') {
            this.videoState = 'INITIAL';
            this.props.onCapture(faceCanvas);
        } else {
            this.progress = 0; // reset
        }
    }


    handleCaptureClick = () => {
        // Change the state or trigger the capture process
        this.videoState = 'CAPTURE'; // Set capture state to 'CAPTURE'
        this.progress = 0; // Reset progress

    }

    render() {
        return (<div className="text-center">
            <video
                className="w-100 h-100 d-none"
                ref={this.videoRef}
            />
            <canvas
                className="w-100 h-100"
                ref={this.canvasRef}
            />
            {
                this.props.hasCapture && (
                    <button
                        className="btn btn-link text-danger mb-2"
                        onClick={this.handleCaptureClick}>
                        <i className="bi bi-record-circle-fill display-6"></i>
                    </button>
                )
            }
        </div>);
    }
}

const defaultProps = {
    hasFPS: false,
    onCapture: () => {
    },
    hasCapture: true
}
CameraCapture.defaultProps = defaultProps;
export default CameraCapture;
