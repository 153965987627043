import browseFile from "../../browseFile";
import csvToJson from "../../csvToJson";
import unflatten from "../../unflatten";
import jsonToObject from "../../jsonToObject";
import BaseListPresenter from "../../base/BaseListPresenter";

class ProductListPresenter extends BaseListPresenter {
  constructor(
    view,
    findObjectUseCase,

    upsertUseCase,
    deleteObjectUseCase
  ) {
    super(view, findObjectUseCase);
    this.upsertUseCase = upsertUseCase;
    this.deleteObjectUseCase = deleteObjectUseCase;
  }

  componentDidMount() {
    this.init();
    this.getObjects();
    this.getAllObjects();
  }

  init() {
    this.limit = 10;
    this.where = {};
    this.search = {};
    this.filter = { status: "Posted" };
    this.include = ["all"];
    this.sort = { createdAt: -1 };
    this.reset();
  }

  componentDidUpdate(prevProps) {
    const query = this.view.getQuery();
    if (query?.where && prevProps?.query?.where !== query?.where) {
      this.reset();
      this.where = JSON.parse(query.where);
      this.getObjects();
      this.getAllObjects();
    }
  }
  async getAllObjects() {
    const collection = "product";
    const query = {
      include: ["all"],
    };
    this.view.showProgress();
    try {
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setAllObjects(this.objects);
      this.view.hideProgress();
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
  async getObjects() {
    const collection = "product";
    const user = this.view.getCurrentUser();
    const skip = (this.current - 1) * this.limit;
    const query = {
      skip: skip,
      where: {
        ...this.where,
        ...this.search,
        ...this.filter,
        createdBy: { id: user?.id },
      },
      include: ["all"],
      sort: this.sort,
    };
    this.view.showProgress();
    try {
      const objects = await this.findObjectUseCase.execute(collection, query);
      this.objects = this.objects.concat(objects);
      this.view.setCount(objects?.length || 0);
      this.view.setObjects(this.objects);

      this.view.hideProgress();
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  async boostProperty(filteredObjects) {
    const highestFeaturedOrder = this.view.state.allObjects.reduce(
      (maxFeaturedOrder, item) => {
        if (item.featuredOrder > maxFeaturedOrder) {
          return item.featuredOrder;
        }
        return maxFeaturedOrder;
      },
      0
    );

    const boost = this.view.getCurrentUser();
    if (boost.boosting === 0) {
      throw new Error("No Boost Available");
    } else {
      boost.boosting -= 1;
    }

    const property = filteredObjects;
    property.acl = this.view.getAcl();
    if (property.featuredOrder === undefined) {
      property.featuredOrder = highestFeaturedOrder + 1;
    } else {
      property.featuredOrder = highestFeaturedOrder + 1;
    }
    console.log(property);
    this.view.showProgress();
    try {
      await this.upsertUseCase.execute("users", boost);
      await this.upsertUseCase.execute("product", property);
      this.view.showSuccess(`Property Boosted Successfully`);

      this.view.hideProgress();
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  async hideProperty(filteredObjects) {
    const property = filteredObjects;
    property.status = "Hidden";

    this.view.showProgress();
    try {
      await this.upsertUseCase.execute("product", property);
      await this.view.showSuccess(`Property Hidden Successfully`);

      this.view.hideProgress();
      this.view.setStatusFilter("Hidden");
      this.filterSubmit({ status: "Hidden" });
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  async unhideProperty(filteredObjects) {
    const property = filteredObjects;
    property.status = "Posted";

    this.view.showProgress();
    try {
      await this.upsertUseCase.execute("product", property);
      await this.view.showSuccess(`Property Hidden Successfully`);

      this.view.hideProgress();
      this.view.setStatusFilter("Posted");
      this.filterSubmit({ status: "Posted" });
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }

  async onClickDeleteSelected() {
    const selected = this.view.getSelected();
    const collection = this.view.getCollectionName();

    try {
      await this.view.showDialog({
        title: "Delete Data?",
        message: "Are you sure you want to delete?",
      });
      for (const obj of selected) {
        await this.deleteObjectUseCase.execute(collection, obj.id);

        const index = this.objects.indexOf(obj);
        this.objects.splice(index, 1);
        this.view.setObjects(this.objects);
      }
      this.view.setSelected([]);
    } catch (error) {
      this.view.hideProgress();
      this.view.showError(error);
    }
  }
}

export default ProductListPresenter;
