import React, {useState} from "react";
import classNames from "../../classNames";
import ProgressBar from "../ProgressBar";

function InputImages({className, onChange, saveImage, ...props}) {
    const [images, setImages] = useState([]); // State to store images with their progress

    const onChangeValue = async (e) => {
        const files = Array.from(e.target.files);

        // Initialize images state with all files, setting initial progress to 0
        setImages(files.map(file => ({file, url: URL.createObjectURL(file), progress: 0})));
        const urls = [];
        for (let i = 0; i < files.length; i++) {
            try {
                const file = files[i];
                const options = {
                    progress: value => {
                        // Update progress for the specific image
                        setImages(prevImages => prevImages.map((img, index) =>
                            index === i ? {...img, progress: value * 100} : img
                        ));
                    }
                };
                const result = await saveImage.execute(file, options);
                urls.push(result.url);
                onChange(urls); // This might be adjusted based on functionality
                // Update the image URL after successful upload (if needed)
            } catch (error) {
                console.log(error);
            }
        }
    }
    const deleteImage = (indexToDelete) => {
        setImages(images.filter((_, index) => index !== indexToDelete));
    };
    return (
        <>
            <input
                type="file"
                accept="image/*"
                className={classNames("form-control", className)}
                onChange={onChangeValue}
                multiple
                {...props}
            />
            <div className="row g-1 mt-2">
                {images.map((image, index) => (
                    <div key={index} className="col-2 position-relative">
                        <img
                            className="img-fluid"
                            src={image.url}
                            alt={`Uploaded ${index + 1}`}
                        />
                        {image.progress < 100 && (
                            <ProgressBar progress={image.progress}/>
                        )}
                        <button
                            type="button"
                            className="btn btn-sm btn-light position-absolute text-danger rounded-circle  d-flex  justify-content-center align-items-center"
                            onClick={() => deleteImage(index)}
                            style={{top: "2px", right: "4px", width: "20px", height: "20px"}}>
                            &times;
                        </button>
                    </div>
                ))}
            </div>
        </>
    );
}

export default InputImages;
