import React from "react";
import { Table, dialog, Button } from "nq-component";
import {
  deleteObjectUseCase,
  findObjectUseCase,
  upsertUseCase,
} from "../../usecases/object";
import { exportCSVUseCase } from "../../usecases/csv/usecases";
import { Progress, InfiniteScroll } from "nq-component";
import withRouter from "../../withRouter";
import Search from "../../components/Search";
import BaseListPage from "../../base/BaseListPage";
import NavBar from "../../components/navbar";
import ApplicationTable from "../../components/ApplicationTable";
import InputFactory from "../../components/InputFactory";
import ProductListPresenter from "./ProductListPresenter";

class ProductListPage extends BaseListPage {
  constructor(props) {
    super(props);
    this.presenter = new ProductListPresenter(
      this,
      findObjectUseCase(),
      upsertUseCase(),
      deleteObjectUseCase()
    );
    this.state = {
      objects: [],
      selected: [],
      allObjects: [],
      total: 0,
      count: 0,
      progress: false,
      statusFilter: "Posted",
    };
  }

  /*when class change*/
  componentDidUpdate(prevProps, prevState) {
    this.presenter.componentDidUpdate(prevProps, prevState);
  }

  closeDialog() {
    dialog.close();
  }

  setAllObjects(allObjects) {
    this.setState({ allObjects });
  }

  setStatusFilter(statusFilter) {
    this.setState({ statusFilter });
  }

  onChangeFilter(type, value, field) {
    const where = {};
    switch (type) {
      case "Pointer":
        if (Object.keys(value)?.length > 0) {
          where[field] = { id: value.id };
        }
        break;
      case "Boolean":
        where[field] = value;
        break;
      default:
        where[field] = { $regex: value, $options: "i" };
    }
    this.searchSubmit(where);
  }

  getCollectionName() {
    return "product";
  }

  onChangeFilterStatus(statusFilter) {
    this.setState({ statusFilter });

    const where = { status: statusFilter };
    this.filterSubmit(where);
  }

  onClickAdd() {
    this.navigateTo("/product/form");
  }
  onClickEdit(index) {
    const object = this.state.objects[index];
    this.navigateTo(`/product/form/${object?.id}`);
  }
  onClickBoost(index) {
    const filteredObjects = this.state.objects[index];

    this.presenter.boostProperty(filteredObjects);
  }

  onClickHide(index) {
    const filteredObjects = this.state.objects[index];

    this.presenter.hideProperty(filteredObjects);
  }
  onClickUnHide(index) {
    const filteredObjects = this.state.objects[index];
    this.presenter.unhideProperty(filteredObjects);
  }
  // viewApplication(index) {
  //   const object = this.presenter.objects[index]
  //   const collection = this.view.getCollectionName()
  //   this.view.navigateTo('/collection/' + collection + '/form/' + object.id)
  // }

  acceptApplication(index, field) {
    this.presenter.acceptApplication(index, field);
  }

  getAcl() {
    const roles = this.getCurrentRoles();
    const aclRoles = roles.map((r) => `role:${r.name}`);
    aclRoles.push("role:admin");
    const user = this.getCurrentUser();
    return {
      read: ["*", user.id, ...aclRoles],
      write: [user.id, ...aclRoles],
    };
  }

  render() {
    const schema = this.getSchema(this.getCollectionName());
    const boost = this.getCurrentUser().boosting;
    const user = this.getCurrentUser();
    const { objects, selected, count, progress, statusFilter, allObjects } =
      this.state;
    if (!schema) return <Progress />;
    const pendingObjects = allObjects.filter(
      (item) => item.status === "Pending" && item.createdBy.id === user.id
    );

    return (
      <>
        <NavBar
          action={() => {
            // if (!user.isMaster) return null
            return (
              <div className="dropdown dropstart d-inline-block">
                <i
                  role="button"
                  data-bs-toggle="dropdown"
                  className="bi bi-three-dots-vertical"
                ></i>
                <div className="dropdown-menu fs-xs">
                  <button
                    onClick={this.onClickDeleteSelected.bind(this)}
                    disabled={selected?.length < 1}
                    className="dropdown-item py-3"
                  >
                    <i className="bi bi-trash pe-2" />
                    Delete Selected
                  </button>
                  {/* <button className='dropdown-item py-3'>
                    <i className='bi bi-check pe-2' />
                    Accept selected
                  </button> */}
                  {/* <div className='dropdown-divider'></div> */}
                </div>
              </div>
            );
          }}
        />
        <div className="overflow-auto">
          <InfiniteScroll
            className="h-100"
            loadMore={this.loadMore.bind(this)}
            hasMore={!progress && count > objects?.length}
          >
            <div className="p-3 p-lg-4">
              <div className="d-flex justify-content-between align-items-center">
                <h1 className="fw-semibold">Property List</h1>
                {selected?.length > 0 ? (
                  <div>
                    <span className="ms-2">Selected: </span>
                    <span className="fs-sm text-nowrap">
                      {selected?.length}
                    </span>
                    <span className="ms-1">of </span>
                    <span className="fs-sm text-nowrap">{count}</span>
                  </div>
                ) : (
                  <div>
                    <div className="text-end">
                      <span className="ms-2">Total: </span>
                      <span className="fs-sm text-nowrap">
                        {objects?.length}
                      </span>
                      <span className="ms-1">of </span>
                      <span className="fs-sm text-nowrap">{count}</span>
                    </div>
                    <div>
                      <span className="ms-2">Boost available: </span>
                      <span className="fs-sm text-nowrap">{boost}</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="d-flex mt-3">
                {Object.keys(schema.filters || {}).map((field) => {
                  let { type, ...options } = schema.filters[field];
                  return (
                    <InputFactory
                      key={field}
                      className="ms-1"
                      type={type}
                      field={field}
                      where={{}}
                      onChange={this.onChangeFilter.bind(this, type)}
                      {...options}
                    />
                  );
                })}
              </div>
              <Search
                schemas={this.getSchemas()}
                className="mt-3"
                onSubmit={this.searchSubmit.bind(this)}
                fields={schema.fields}
              />
              <div className="mt-3 d-flex gap-2">
                <button
                  className={`btn border text-primary border-primary ${
                    statusFilter === "Posted" && "btn-primary text-white"
                  }`}
                  onClick={() => this.onChangeFilterStatus("Posted")}
                >
                  Posted
                </button>
                <button
                  className={`btn border text-primary position-relative border-primary ${
                    statusFilter === "Pending" && "btn-primary text-white"
                  }`}
                  onClick={() => this.onChangeFilterStatus("Pending")}
                >
                  Pending
                  {pendingObjects?.length > 0 && (
                    <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                      {pendingObjects?.length}
                    </span>
                  )}
                </button>
                <button
                  className={`btn border text-primary position-relative border-primary ${
                    statusFilter === "Hidden" && "btn-primary text-white"
                  }`}
                  onClick={() => this.onChangeFilterStatus("Hidden")}
                >
                  Hidden
                </button>
                <button
                  className={`btn border text-primary position-relative border-primary ${
                    statusFilter === "Rejected" && "btn-primary text-white"
                  }`}
                  onClick={() => this.onChangeFilterStatus("Rejected")}
                >
                  Rejected
                </button>
              </div>
              <ApplicationTable
                fields={schema.fields}
                objects={objects}
                selectable
                collapsable
                excludeFields={[
                  ...Object.keys(schema.fields).reduce(
                    (acc, key) => {
                      const options = schema.fields[key];
                      if (options.read === false) {
                        acc.push(key);
                      }
                      switch (options._type || options.type) {
                        case "Relation":
                        case "Array":
                        case "Object":
                        case "File":
                          acc.push(key);
                          break;
                        default:
                      }
                      return acc;
                    },
                    ["acl", "password"]
                  ),
                  "id",
                  "photos",
                  "acl",
                  "updatedAt",
                  "createdAt",
                  "createdBy",
                  "isApproved",
                  "featuredOrder",
                ]}
                selected={selected}
                onSelect={this.onSelect.bind(this)}
                onSelectAll={this.onSelectAll.bind(this)}
                progress={progress}
                onClickItem={this.onClickItem.bind(this)}
                className="mt-3"
                actions={[
                  {
                    label: "EDIT",
                    onClick: this.onClickEdit.bind(this),
                    className: "btn btn-primary ms-2",
                  },
                  {
                    label: "BOOST",
                    onClick: this.onClickBoost.bind(this),
                    className: "btn btn-info text-white ms-2",
                  },
                  {
                    label: "HIDE",
                    onClick: this.onClickHide.bind(this),
                    className: "btn btn-danger text-white ms-2",
                  },
                  {
                    label: "UNHIDE",
                    onClick: this.onClickUnHide.bind(this),
                    className: "btn btn-success text-white ms-2",
                  },
                ]}
              />
            </div>
          </InfiniteScroll>
        </div>
        <div className="position-fixed bottom-0 end-0 m-4">
          <Button
            className="btn btn-primary shadow-sm bg-primary"
            onClick={this.onClickAdd.bind(this)}
            style={{ width: "50px", height: "50px", borderRadius: "25px" }}
          >
            <i className="bi bi-plus-lg" />
          </Button>
        </div>
      </>
    );
  }
}

export default withRouter(ProductListPage);
