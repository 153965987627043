import BasePage from "../../base/BasePage";
import NavBar from "../../components/navbar";
import React from "react";
import withRouter from "../../withRouter";

class SellerApprovalPage extends BasePage {

    componentDidMount() {
        const document = this.getGlobalState('sellerDocument');
        if (document.status === 'Approved') {
            this.showSuccess('Your request has been approved')
                .then(() => this.navigateTo('/'));
        }
        if (document.status === 'Rejected') {
            this.showError('Your request has been rejected contact us at contact@ebowr.com');
        }
    }

    onClickEdit() {
        this.navigateTo('/seller/form');
    }

    render() {
        return (
            <>
                <NavBar/>
                <div className="overflow-auto">
                    <div className="h-100">
                        <div className="p-3 p-lg-4 text-center">
                            <h1 className="fw-bold mt-3 text-capitalize">Your Request is Pending</h1>
                            <button
                                onClick={this.onClickEdit.bind(this)}
                                type="button" className="btn btn-primary fs-sm">EDIT DOCUMENT
                            </button>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(SellerApprovalPage);