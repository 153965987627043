import React from "react";
import InputFactory from "../ApplicationInput";
import FormTab from "./FormTab";
import checkCondition from "./checkCondition";

/**
 * Group the fields in the schema based on sections and return the grouped fields object.
 * @param {Object} schema - The schema object containing the fields and sections.
 * @returns {Object} - The grouped fields object.
 */
function group(schema) {
  const { fields, sections } = schema;
  const groups = {
    General: {},
  };
  for (const key in fields) {
    const options = fields[key];
    if (sections && sections[options.section]) {
      groups[options.section] = groups[options.section] || {};
      groups[options.section][key] = options;
    } else {
      groups["General"][key] = options;
    }
  }
  return groups;
}

const defaultProps = {
  excludeFields: [],
  hasLabel: true,
};

/**
 * FormFactory component renders a form based on the provided schema and object data.
 * @param {Object} props - The component props.
 * @param {Object} props.schema - The schema object defining the form structure.
 * @param {Object} props.object - The object data to populate the form fields.
 * @param {Function} props.onChange - The callback function called when a form field value changes.
 * @returns {JSX.Element} - The rendered form component.
 */
function FormFactory({
  className,
  schema,
  object,
  onChange,
  excludeFields,
  hasLabel,
  ...props
}) {
  const tabs = schema.tabs;
  const initialTab =
    tabs && Object.keys(tabs).length > 0 ? Object.keys(tabs)[0] : null; // Set the initial tab value
  const [_tab, setTab] = React.useState(initialTab);
  const sections = schema.sections || {};
  const groups = group(schema);
  return (
    <>
      <FormTab onSet={setTab} tabs={tabs} object={object} />
      {Object.keys(groups).map((key) => {
        const fields = groups[key];
        const { label } = sections[key] || {};
        const components = Object.keys(fields)
          .map((field) => {
            if (object.category === undefined) {
              if (field === "subcategory") return null;
              if (field === "indoor") return null;
              if (field === "outdoor") return null;
            }
            if (
              object.category === "Commercial" ||
              object.category === "Land"
            ) {
              if (field === "bedrooms") return null;
              if (field === "bathrooms") return null;
            }
            if (object.category === "Land") {
              if (field === "floorArea") return null;
              if (field === "indoor") return null;
              if (field === "outdoor") return null;
            }
            if (excludeFields.includes(field)) return null;
            const conditions =
              fields[field].if || fields[field].or || fields[field].and;
            if (conditions && !checkCondition(fields[field], object)) {
              return null;
            }
            let { type, pattern, write, tab, col, ...options } = fields[field];
            if (_tab && tab && _tab !== tab) return null;
            if (write === false) return null;
            if (field === "password") {
              type = "Password";
            }

            let labelText = options.label || field;
            if (labelText === "Name") {
              labelText =
                object.category === "Condominium"
                  ? "Enter Condominium Name"
                  : object.category === "Commercial"
                  ? "Enter Building Name"
                  : object.category === "Apartment" ||
                    object.category === "House" ||
                    object.category === "Land"
                  ? "Enter Subdivision Name"
                  : "Property Name";
            }

            return (
              <div className={`${col || className} py-2`} key={field}>
                {type !== "Boolean" && type !== "More" && hasLabel && (
                  <label className="form-label fw-semibold fs-sm text-capitalize ">
                    {labelText}
                    {options?.required === true ? (
                      <span className="text-danger">*</span>
                    ) : null}
                  </label>
                )}
                {props.componentFactory ? (
                  React.createElement(props.componentFactory, {
                    object: object,
                    field: field,
                    onChange: onChange,
                    type: type,
                    ...options,
                    ...props,
                  })
                ) : (
                  <InputFactory
                    object={object}
                    field={field}
                    onChange={onChange}
                    type={type}
                    {...options}
                    {...props}
                  />
                )}
              </div>
            );
          })
          .filter((c) => c);
        return (
          <>
            {components.length > 0 && Object.keys(sections).length > 0 && (
              <div className="col-12">
                <p className="small fw-bold mb-0 ms-1">{label || key}</p>
                <hr />
              </div>
            )}
            {components}
          </>
        );
      })}
    </>
  );
}

FormFactory.defaultProps = defaultProps;
export default FormFactory;
