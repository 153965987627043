import React from 'react'
import MainPagePresenter from './MainPagePresenter'
import {Menu} from 'nq-component'
import {getAllSchemasUseCase} from '../../usecases/schema/usecases'
import {getCurrentUserUseCase, signOutUseCase} from '../../usecases/user'
import {Routes, Route} from 'react-router-dom'
import {OffCanvas} from 'nq-component'
import CollectionListPage from '../collection-list/CollectionListPage'
import CollectionFormPage from '../collection-form/CollectionFormPage'
import BasePage from '../../base/BasePage'
import NotFoundPage from '../notfound'
import {Layout, Progress, LogoHolder} from 'nq-component'
import AccountPage from '../account/AccountPage'
import RoleFormPage from '../role-form/RoleFormPage'
import withRouter from '../../withRouter'
import DashboardPage from '../dashboard/DashboardPage'
import ProductFormPage from '../product-form/ProductFormPage'
import ProductListPage from '../product-list/ProductListPage'
import SellerFormPage from "../seller-form/SellerFormPage";
import {findObjectUseCase} from "../../usecases/object";
import SellerApprovalPage from "../seller-approval/SellerApprovalPage";

class MainPage extends BasePage {
    constructor(props) {
        super(props)
        this.presenter = new MainPagePresenter(
            this,
            getCurrentUserUseCase(),
            signOutUseCase(),
            getAllSchemasUseCase(),
            findObjectUseCase()
        )
    }

    componentDidMount() {
        this.presenter.componentDidMount()
    }

    onClickSignOut() {
        this.presenter.onClickSignOut()
    }

    onClickMenu(e, item) {
        e.preventDefault()
        this.navigateTo(item.route)
    }

    render() {
        const user = this.getCurrentUser()
        if (this.state.loading) {
            return (
                <div className='min-vh-100 d-flex justify-content-center align-items-center '>
                    <Progress/>
                </div>
            )
        }
        const document = this.getGlobalState('sellerDocument');
        const isApproved = document === undefined || document.status !== 'Approved';
        const menus = [
            {
                "name": "Dashboard",
                "route": "/collection/dashboard",
                "icon": "bi bi-pie-chart",
                "disabled": isApproved
            },
            {
                "name": "Property List",
                "route": "/collection/properties",
                "icon": "bi bi-house-down",
                "disabled": isApproved
            }
        ]
        return (
            <Layout>
                <Layout.Context.Consumer>
                    {(value) => (
                        <OffCanvas onSetShow={value.setCollapse} show={value.collapsed}>
                            <div className='offcanvas-body'>
                                <nav className='navbar-dark'>
                                    <div className='text-center'>
                                        <LogoHolder
                                            className='bg-white'
                                            textClassName='text-dark'
                                            logo={user.avatar}
                                            name={user.username}
                                        />
                                        <p className='text-white mt-3 text-capitalize '>
                                            {user.name || user.username}
                                        </p>
                                    </div>
                                    <hr className='border border-white border-1 opacity-25'/>
                                    <Menu
                                        onClickItem={this.onClickMenu.bind(this)}
                                        menus={menus}
                                    />
                                </nav>
                            </div>
                            <hr className='border border-white my-0  border opacity-25'/>
                            <div className='p-3 '>
                                <button
                                    className='nav-link text-white btn btn-link'
                                    onClick={this.onClickSignOut.bind(this)}
                                >
                                    <i className='bi bi-power'></i>
                                    <span className='ms-2 fw-bold small'>Log out</span>
                                </button>
                            </div>
                        </OffCanvas>
                    )}
                </Layout.Context.Consumer>
                <main className='vh-100 d-flex flex-column'>
                    <Routes>
                        <Route exact path={'/'} element={<DashboardPage/>}/>
                        <Route
                            exact
                            path={'/collection/dashboard'}
                            element={<DashboardPage/>}
                        />
                        <Route
                            exact
                            path={'/collection/:name'}
                            element={<CollectionListPage/>}
                        />
                        <Route path={'/collection/roles/form'} element={<RoleFormPage/>}/>
                        <Route
                            path={'/collection/roles/form/:id'}
                            element={<RoleFormPage/>}
                        />
                        <Route
                            path={'/collection/:name/form/'}
                            element={<CollectionFormPage/>}
                        />
                        <Route
                            path={'/collection/:name/form/:id'}
                            element={<CollectionFormPage/>}
                        />
                        <Route path={'/seller/form'} element={<SellerFormPage/>}/>
                        <Route path={'/seller/approval'} element={<SellerApprovalPage/>}/>
                        <Route path={'/product/form'} element={<ProductFormPage/>}/>
                        <Route path={'/product/list'} element={<ProductListPage/>}/>
                        <Route path={'/product/form/:id'} element={<ProductFormPage/>}/>
                        <Route path={'/account'} element={<AccountPage/>}/>
                        <Route element={<NotFoundPage/>}/>
                    </Routes>
                </main>
            </Layout>
        )
    }
}

export default withRouter(MainPage)
