import {
  InputFile,
  InputIcon,
  InputImage,
  InputJson,
  InputNumber,
  InputPassword,
  InputPointer,
  InputRelation,
  InputSignature,
  InputString,
  InputText,
  RelatedFactory,
} from "nq-component";
import InputLamudi from "../InputLamudi";
import Checkbox from "../Checkbox";
import React, { useState, useEffect } from "react";
import InputFile2 from "../InputImages";
import { saveImageUseCase } from "../../usecases/file";
import Buttonbox from "../Buttonbox";
import InputSelect from "../InputSelect";

const defaultProps = {
  schemas: [],
};

function InputFactory({
  type,
  _type,
  field,
  object,
  schemas,
  onChange,
  ...props
}) {
  const value = object && object[field];

  function _onChange(field, value) {
    if (object) {
      object[field] = value;
    }
    onChange(value, field);
  }
  switch (_type || type) {
    case "Email":
    case "String":
      return (
        <InputString
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    case "Date":
      return (
        <InputString
          defaultValue={value && value.slice(0, 10)}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          {...props}
        />
      );
    case "Password":
      return (
        <InputPassword onChange={_onChange.bind(this, field)} {...props} />
      );
    case "Number":
    case "Tel":
      return (
        <InputNumber
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "Text":
      return (
        <InputText
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "Relation":
      return (
        <InputRelation
          field={field}
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          {...props}
        />
      );
    case "Pointer":
      return (
        <InputPointer
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          {...props}
        />
      );
    case "Related":
      return (
        <RelatedFactory
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          schema={
            props.schema || schemas.find((s) => s.collection === props.target)
          }
          schemas={schemas}
          field={field}
          {...props}
        />
      );
    case "Image":
      return (
        <InputImage
          value={value}
          onChange={_onChange.bind(this, field)}
          saveImage={saveImageUseCase()}
          {...props}
        />
      );
    case "File":
      return (
        <InputFile
          value={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "Signature":
      return (
        <InputSignature
          value={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "Boolean":
      return (
        <Checkbox
          checked={value}
          onChange={_onChange.bind(this, field)}
          id={field}
          {...props}
        />
      );
    case "More":
      return (
        <Buttonbox
          checked={value}
          onChange={_onChange.bind(this, field)}
          id={field}
          {...props}
        />
      );
    case "Object":
    case "Array":
      return (
        <InputJson
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "Enum":
      return (
        <InputSelect
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          items={object}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );
    case "Feature":
      return (
        <InputLamudi
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          type={type.toLowerCase()}
          items={object}
          placeholder={
            props.placeholder ||
            (props.dynamic ? "Select of type " : "Select ") +
              (props.label || field)
          }
          {...props}
        />
      );

    case "Icon":
      return (
        <InputIcon
          defaultValue={value}
          onChange={_onChange.bind(this, field)}
          {...props}
        />
      );
    case "MultipleImg":
      return (
        <InputFile2
          className="form-control"
          field="photos"
          onChange={_onChange.bind(this, field)}
          object={value}
          {...props}
        />
      );

    default:
      return null;
  }
}

InputFactory.defaultProps = defaultProps;
export default InputFactory;
